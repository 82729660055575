
body {
    font-family: Helvetica, Arial, '微軟正黑體', '新細明體', sans-serif !important;
    line-height: 1;
    font-size: 100%;
    min-width: 1366px;
    min-height: 768px;
    background-color: #fff;
}

#root {
    height: 100%;
}

.app-layout {
    height: 100%;
    background-color: #f6f9fb;
  }

.app-content {
    height: 100%;
}

.app-page-header{
    width: 100%;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #D8D8D8 100%) 0% 0% no-repeat padding-box;
    line-height: 0;
    height: 38%;
    position: relative;
    display: flex;
    direction: ltr;
}

.app-page-pic {
    display: inline-block;
    width: 55%;
    height: 100%;
    position: absolute;
    left: 7px;
    top: -3px;
    overflow: hidden;
}

    .app-page-pic img {
        max-width: 100%;
    }

.app-page-logo {
    width: 45%;
    position: absolute;
    right: 0;
    height: 100%;
    overflow: hidden;
}

    .app-page-logo > img {
        max-width: 100%;
        width: auto;
        height: auto;
        margin: auto;
        display: block;
    }

    .app-page-logo .rfdme-logo {
        margin-top: 30px;
    }

.app-page-select-wrap {
    position: relative;
    right: 0;
    margin: auto;
    display: block;
    width: 70%;
    height: 12%;
    margin-top: 15px;
}

.app-page-content {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 62%;
    position: relative;
    display: flex;
}

.app-page-card-wrap {
    margin: auto;
    width: 80%;
    display: flex;
    z-index: 100;
    position: absolute;
    top: -130px;
    left: 0;
    right: 0;
    height: 42%;
}

.app-page-card {
    background-color: white;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 3px 3px 6px #00000029;
    height: 100%;
    width: 100%;
    display: inline-block;
    position: relative;
}

    .app-page-card .cover {
        background: transparent linear-gradient(180deg, #40BFD9 0%, #509DDE 100%) 0% 0% no-repeat padding-box;
        display: inline-block;
        width: 100%;
        height: 75%;
        border-radius: 10px 10px 0px 0px;
        position: relative;
        margin: 0;
    }

    .app-page-card .cover img {
        position: absolute;
        top: 0;
        bottom: 20%;
        left: 0;
        right: 0;
        margin: auto;
        height: 55%;
        width: 55%;
    }

    .app-page-card .cover .num {
        font-size: 3.5rem;
        letter-spacing: 0;
        color: #FFFFFF;
        position: absolute;
        bottom: 1%;
        display: inline-block;
        right: 40%;
        font-weight: bold;
    }

    .app-page-card .cover .unit {
        font-size: 1.8rem;
        letter-spacing: 0;
        color: #FFFFFF;
        position: absolute;
        bottom: 10%;
        left: 65%;
        margin-bottom: auto;
        display: inline-block;
    }

.app-page-card p {
    margin: auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #0072A5;
    line-height: 2.5;
}

.app-page-data-wrap {
    width: 55%;
    height: 60%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-right: 0;
}

.app-page-data {
    width: 100%;
    height: 90%;
    background: transparent linear-gradient(180deg, #FAFAFA 0%, #F0F0F0 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 5px #DDDDDD;
    border-radius: 10px;
    border-left: 10px #40BFD9 solid;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    top: 5%;
    margin-left: 0;
}

    .app-page-data .title {
        font-size: 1.8rem;
        display: inline-block;
        position: absolute;
        left: 8%;
        top: 10%;
        font-weight: bold;
    }

    .app-page-data .triggertime {
        font-size: 1.3rem;
        display: inline-block;
        position: absolute;
        left: 8%;
        top: 30%;
        font-weight: bold;
        color: #707070;
    }

    .app-page-data .num {
        font-size: 2.3rem;
        letter-spacing: 0;
        color: #707070;
        position: absolute;
        bottom: 25%;
        display: inline-block;
        left: 0;
        right: 0;
        text-align: center;
    }

    .app-page-data .num .focus {
        color: #40BFD9;
    }

    .app-page-data .unit {
        font-size: 1.5rem;
        letter-spacing: 0;
        color: #707070;
        position: absolute;
        bottom: 7%;
        right: 10%;
        margin-bottom: auto;
        display: inline-block;
    }

.app-page-chart-wrap {
    width: 35%;
    height: 60%;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 50px;
    margin-left: 0;
}

.app-page-chart {
    width: 100%;
    height: 95%;
    background: transparent linear-gradient(180deg, #FAFAFA 0%, #F0F0F0 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 5px #DDDDDD;
    border-radius: 10px;
    border-left: 10px #40BFD9 solid;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    margin: auto;
}

.app-page-footer {
    display: flex;
    position: relative;
    bottom: 30px;
    padding: 0;
    width: 100%;
    font-size: 18px;
    background: #fff;
}

    .app-page-footer p {
        margin: 0 auto;
    }

.ant-select-item-option-content {
    font-size: 1.8rem;
    line-height: 1.25;
    padding-left: calc(3rem - 3px);
}

#chart {
    width: 100%;
    height: 100%;
    margin: 10px 10px;
  }
  .apexcharts-text,
  .apexcharts-title-text {
    font-size: 120%;
    font-family: inherit;
  }

  .apexcharts-xaxis-title-text,
  .apexcharts-yaxis-title-text {
    font-weight:initial;
  }

#login-button {
    float: right;
    display: block;
    width: 120px;
    height: 40px;
    margin-top: 30px;
    background-color: #40bfd9;
    border-radius: 5px;
    color: white;
    font-size: 18px;
    border: none;
    font-family: 'Noto Sans TC', '微軟正黑體';
}